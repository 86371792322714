import {MY_CONSTANT} from './constants.js';

//常量返回
export function MyConstant(key = false,value=MY_CONSTANT) {
    // console.log(key);
    if (key) {
        const keys = key.split('.'); // 将 key 拆分成多个层级

        // 使用递归获取嵌套属性的值
        for (const k of keys) {
            value = value[k];
            if (typeof value === 'undefined') {
                console.log(k)
                return undefined; // 如果属性不存在，返回 undefined
            }
        }
        return value;
    }
    return value;
}

// 定义你的接口常用查询方法
export function getApiWhere({
                                filter = MY_CONSTANT.getWhere.filter,
                                op = MY_CONSTANT.getWhere.op,
                                limit = MY_CONSTANT.getWhere.limit,
                                order = MY_CONSTANT.getWhere.order,
                                page = MY_CONSTANT.getWhere.page
                            } = {}) {
    return {filter: filter, op: op, limit: limit, order: order, page: page};
}
//资讯时间转换
export function formatTime(newtime) {

    const newtimestamp = new Date(newtime*1000).getTime();
    const diff = Math.abs(new Date() - newtimestamp);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);

    if (hours < 1) {
        return '刚刚';
    } else if (hours < 24) {
        return `${hours}小时前`;
    } else if (days < 10) {
        return `${days}天前`;
    } else {
        const timestamp = newtime * 1000;
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
}

//判断为空
export function empty(str) {
    return typeof (str) === "undefined" || str === null || str === "" || str === "NaN";
}

//cookie用户信息
export function getCookie(key = '',cookies=$cookies) {


    if (cookies.isKey("Cookie")) {

        if (empty(key)) {
            return cookies.get("Cookie");
        }
        // JSON.parse
        return cookies.get("Cookie")[key];
    }
    return false;


}

//加密字符串*号展示
export function stringStarShow(string = '', {startLong = 3, endLong = 4, StarNum = 4} = {}) {
    const stars = '*'.repeat(StarNum);

    return string.slice(0, startLong) + stars + string.slice(-endLong);
}

//使用模板
export function templatePlugin() {
    //全部使用中文模版先...
    // let template=MY_CONSTANT.Equipment=='mobile'?'zh-cn':MY_CONSTANT.setApiLang
    return MY_CONSTANT.Equipment + '-' + 'zh-cn';
    // return MY_CONSTANT.Equipment + '-' + MY_CONSTANT.setApiLang;
}


// 加密函数
export function encryptString(text) {

    let encryptedText = "";
    for (let i = 0; i < text.toString().length; i++) {
        let charCode = text.toString().charCodeAt(i) + 1; // 将字符的 ASCII 值加1
        encryptedText += String.fromCharCode(charCode);
    }
    // console.log(encryptedText,decryptString(encryptedText))
    return encryptedText;
    // return window.btoa(text);
}

// 解密函数
export function decryptString(encryptedText) {
    let decryptedText = "";
    for (let i = 0; i < encryptedText.length; i++) {
        let charCode = encryptedText.charCodeAt(i) - 1; // 将字符的 ASCII 值减1
        decryptedText += String.fromCharCode(charCode);
    }
    return decryptedText;
    // return window.atob(encryptedText);
}

//展会日期转换
export function ExhibitionDate(startDate = MY_CONSTANT.Api_CONSTANT.SizeConfig.ini_cpse_date, endDate = MY_CONSTANT.Api_CONSTANT.SizeConfig.ini_cpse_enddate, lang = MY_CONSTANT.sqlWhereLang) {

    const start = new Date(startDate.split(" ")[0]);


    const end = new Date(endDate.split(" ")[0]);
    const startMonth = lang === "cn" ? start.getMonth() + 1 : getMonthInEnglish(start.getMonth() + 1);
    const startDay = start.getDate();
    const startSuffix = getSuffixInEnglish(startDay);
    const endMonth = lang === "cn" ? end.getMonth() + 1 : getMonthInEnglish(end.getMonth() + 1);
    const endDay = end.getDate();
    const endSuffix = getSuffixInEnglish(endDay);

    if (!endDate) {
        if (lang === "cn") {
            return `${start.getFullYear()}年${startMonth}月`;
        } else {
            return `${startMonth}, ${start.getFullYear()}`;
        }
    } else {
        if (lang === "cn") {
            return `${start.getFullYear()}年${startMonth}月${startDay}日-${endMonth}月${endDay}日`;
        } else {
            return `${startMonth} ${startDay}${startSuffix} - ${endMonth} ${endDay}${endSuffix}, ${start.getFullYear()}`;
        }
    }
}

function getMonthInEnglish(month) {
    const englishMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return englishMonths[month - 1];
}

function getSuffixInEnglish(day) {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}
